import React, { useEffect, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';

const useRequest = <T,>(option?: AxiosRequestConfig & { manual?: boolean }) => {
  const [error, setError] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const request = async (requestOptions?: AxiosRequestConfig & { manual?: boolean } & {customHeaders?: any}) => {
    const loginToken = localStorage.getItem("token");
    console.log(requestOptions);
    const headers = {
      'Authorization': loginToken ? `Bearer ${loginToken}` : '',
      'Content-Type': 'application/json',
      ...requestOptions?.customHeaders
    };

    try {
      const response = await axios.request<T>({
        ...option,
        ...requestOptions,
        headers,
      });
      setData(response.data);
      return response.data;
    } catch (e: any) {
      if (e?.response?.status === 401) {
        localStorage.removeItem("token");
      }
      setError(e.message || "unknown request error.");
      throw new Error(e);
    } finally {
      setLoaded(true);
    }
  };

  return { error, loaded, data, request };
};
export default useRequest;
