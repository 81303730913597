import React, { useState, useEffect } from 'react';
import { Spin } from 'antd';
import useRequest from "@/hooks/useRequest";
import getQueryParams from "@/utils/url-search";
import { useNavigate } from 'react-router-dom';
const Auth = () => {
  const navigate = useNavigate();

  const { request } = useRequest({
    url: "/api/shop/auth_callback",
    method: "post",
    manual: true,
    data: getQueryParams(window.location.href)
  });

  useEffect(() => {
    request().then((data: any) => {
      console.log(data, 'data');
      navigate('/');
    }).catch((err) => {
      console.error('Request error:', err);
    })
  }, []);

  return (
    <div style={{
      height: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }}>
      <Spin />
    </div>
  );
};

export default Auth;
