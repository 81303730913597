import 'normalize.css';
import { RouterProvider} from 'react-router-dom'
import router from "./router";
import Client, { shared, Oauth } from '@shoplinedev/appbridge'
import {useEffect, useState} from 'react'
import useRequest from "@/hooks/useRequest";
import getQueryParams from "@/utils/url-search";
import './styles/global.css'
import { APIKEY,SCOPE, REDIRECTURL } from "@/utils/constants";

const App = () => {
  const [token, setToken] = useState('');
  const { request } = useRequest({
    url: "/api/shop/installed_uninstalled",
    method: "post",
    manual: true,
    data: getQueryParams(window.location.href)
  });

  useEffect(() => {
    if(!localStorage.getItem('token')) {
    console.log("getAuth")
      const app = Client.createApp({
        appKey: APIKEY,
        host: shared.getHost()
      })
      const getSessionToken = async (app:any) => {
        try {
          const res = await shared.getSessionToken(app);
          localStorage.setItem('token', res);
          setToken(res); // 设置令牌状态

          // 检测安装
          request().then((data: any) => {
            if(!data.installed) {
              Oauth.create(app).invoke({
                scope: SCOPE,
                appKey: APIKEY,
                redirectUri: REDIRECTURL
              })
            }
          }).catch((err) => {
            console.error('Request error:', err);
          })
        } catch (error) {
          console.error('Error getting session token:', error);
        }
      }
      getSessionToken(app)
    }
  }, [])

  return <RouterProvider router={router}/>
}

export default App;
