import React, {useEffect, useState} from 'react';
import {Table, Space, Modal, Button, Row, Col, Typography, Card} from 'antd';
import {EditOutlined, DeleteOutlined} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import useRequest from "@/hooks/useRequest";
import getQueryParams from "@/utils/url-search";

const {Title} = Typography;

const ProductList = () => {
    const navigate = useNavigate();
    const {request} = useRequest();

    const [dataSource, setDataSource] = useState([]);
    const fetchList = async () => {
        try {
            await request({
                url: "/api/advertise",
                method: "get",
                manual: true
            }).then((data: any) => {
                let refresh: any = []
                console.log(data)
                if (data.data) {
                    data.data.forEach((item: any) => {
                        refresh.push({
                            key: item.id,
                            id: item.id,
                            collection_id: item.collection_id,
                            createTime: item.created_at
                        })
                    })
                }
                setDataSource(refresh)
            }).catch((err) => {
                console.error('Request error:', err);
            })
        } catch (e) {
            console.error('Request error:', e);
        }
    }

    useEffect(() => {
        fetchList()
    }, [])


    const handleEdit = (record: any) => {
        console.log('Edit:', record);
        navigate(`/detail/${record.id}`);
    };

    const handleDelete = (record: any) => {
        Modal.confirm({
            title: '确认删除',
            content: `你确定要删除${record.collection_id}吗？`,
            okText: '确认',
            cancelText: '取消',
            onOk: () => {
                console.log('Delete:', record);
                request({
                    url: `/api/advertise/${record.id}`,
                    method: "delete",
                    manual: true,
                }).then(res => {
                    fetchList()
                })
            },
        });
    };

    const handleAdd = () => {
        navigate('/detail/create');
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: '商品集ID',
            dataIndex: 'collection_id',
            key: 'collection_id',
        },
        {
            title: '创建时间',
            dataIndex: 'createTime',
            key: 'createTime',
        },
        {
            title: '操作',
            key: 'action',
            render: (text: any, record: any) => (
                <Space size="middle">
                    <EditOutlined onClick={() => handleEdit(record)} style={{cursor: 'pointer'}}/>
                    <DeleteOutlined onClick={() => handleDelete(record)} style={{cursor: 'pointer'}}/>
                </Space>
            ),
        },
    ];

    return (
        <Card style={{margin: 20}}>
            <Row justify="space-between" align="middle" style={{marginBottom: 16}}>
                <Col>
                    <Title level={4}>商品集广告</Title>
                </Col>
                <Col>
                    <Button type="primary" onClick={handleAdd}>
                        新增广告位
                    </Button>
                </Col>
            </Row>
            <Table dataSource={dataSource} columns={columns}/>
        </Card>
    );
};

export default ProductList;
