import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Upload, message, Row, Col, Card } from 'antd';
import { UploadOutlined, PlusOutlined, DeleteOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { RcFile } from 'antd/es/upload/interface';
import styles from "./index.module.css";
import useRequest from "@/hooks/useRequest";
import { IMAGE_PREFIX } from "@/utils/constants";
import { useNavigate, useParams } from "react-router-dom";
// @ts-ignore
import { v4 as uuidv4 } from 'uuid';
const { Option } = Select;

interface FormData {
  title: string;
  collectionId: string;
  advertises: AdItem[];
}

interface AdItem {
  key: string;
  title: string;
  subTitle: string;
  position: string;
  link: string;
  bgColor: string;
  arrowColor: string;
  image: any;
  id?: number;
}

const AdForm: React.FC = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState<FormData>({
    title: '',
    collectionId: '',
    advertises: [{
      key: uuidv4(),
      title: '',
      subTitle: '',
      position: '',
      link: '',
      bgColor: '#ffffff',
      arrowColor: '#000000',
      image: null,
    }],
  });
  const [collections, setCollections] = useState([]);
  const { request } = useRequest();
  const routeParam = useParams();
  const id = routeParam.id;

  const [form] = Form.useForm();

  const fetchCollection = async () => {
    try {
      const res = await request({
        url: "/api/shop/collections",
        method: "get",
        manual: true,
      }) as [];
      if (res.length) {
        setCollections(res);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const fetchDetail = async () => {
    try {
      if (id && id !== 'create') {
        const data = await request({
          url: `/api/advertise/${routeParam.id}`,
          method: "get",
          manual: true
        }) as any;
        let advertises: AdItem[] = [];
        data.advertises.forEach((ad: any) => {
          const imageMap = ad?.image?.id ? {id: ad?.image?.id, path: ad?.image?.path}: null
          advertises.push({
            key: uuidv4(),
            title: ad.title,
            subTitle: ad.sub_title,
            position: ad.position,
            link: ad.link,
            bgColor: ad.bg_color || '#ffffff',
            arrowColor: ad.arrow_color || '#000000',
            image: imageMap,
          });
        });

        const detail = {
          title: data.title,
          collectionId: data.collection_id,
          advertises: advertises,
        };

        setFormData(detail);  // 更新本地状态
        form.setFieldsValue(detail);  // 更新表单值
      }
    } catch (e) {
      console.log(e);
    }
  };

  // 获取详情
  useEffect(() => {
    Promise.all([
      fetchCollection(),
      fetchDetail(),
    ])
  }, []);

  const handleFormChange = (changedValues: any, allValues: any) => {
    setFormData(allValues);
  };

  const handleImageUpload = async (file: RcFile, index: number) => {
    const forms = { ...formData };
    const newadvertises = forms.advertises;

    try {
      const formData = new FormData();
      formData.append('image', file);
      const response = await request({
        url: "/api/upload",
        method: "POST",
        data: formData,
        manual: true,
        customHeaders: {
          'Content-Type': 'multipart/form-data;',
        }
      }) as any;

      // 更新 formData 中的 image 字段为远程 URL
      newadvertises[index].image = {
        // @ts-ignore
        path: response?.path,
        id: response?.id,
      };
      forms.advertises = newadvertises;
      setFormData({ ...forms });
      form.setFieldsValue({ advertises: newadvertises });  // 确保表单值更新
      message.success('图片上传成功');
    } catch (error) {
      console.error('上传出错:', error);
      message.error('图片上传失败');
    }
  };

  const handleSubmit = () => {
    const postData = {
      title: formData.title,
      collection_id: formData.collectionId,
      advertises: [],
    };
    const advertises = formData.advertises.map((ad: AdItem) => ({
      title: ad.title,
      sub_title: ad.subTitle,
      position: ad.position,
      link: ad.link,
      bg_color: ad.bgColor || '#ffffff',
      arrow_color: ad.arrowColor || '#000000',
      image_id: ad?.image?.id
    }));
    // @ts-ignore
    postData['advertises'] = advertises;

    let url = "/api/advertise";
    let method = "POST";
    if (routeParam.id && routeParam.id !== 'create') {
      url = `/api/advertise/${routeParam.id}`;
      method = 'PUT';
    }

    request({
      url: url,
      method: method,
      data: postData,
      manual: true
    }).then(() => {
      message.success('保存成功');
      // navigate('/');
    }).catch((err) => {
      console.log(err);
    });
  };

  const addItem = () => {
    if (formData.advertises.length < 3) {
      formData.advertises.push(
        {
          key: uuidv4(),
          title: '',
          subTitle: '',
          position: '',
          link: '',
          bgColor: '#ffffff',
          arrowColor: '#000000',
          image: null,
        }
      )
      const result = {...formData}
      setFormData(result);
      form.setFieldsValue(result);
    } else {
      message.warning('最多只能添加3个Item');
    }
  };

  const removeItem = (key: string) => {
    console.log(key);
    console.log(formData.advertises);
    const newadvertises = formData.advertises.filter((_, i) => _.key !== key);
    const result = { ...formData, advertises: newadvertises }
    setFormData(result);
    form.setFieldsValue(result);
  };

  return (
    <Row gutter={16}>
      <Col xs={24} md={24}>
        <Card
          title={(
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowLeftOutlined
                onClick={() => navigate(-1)}
                style={{ fontSize: '20px', cursor: 'pointer', marginRight: '16px' }}
              />
              {id === 'create' ? "新建广告" : "编辑广告"}
            </div>
          )}
          style={{ backgroundColor: '#ffffff' }}
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={formData}
            onValuesChange={handleFormChange}
            onFinish={handleSubmit}
          >
            <Form.Item label="广告位标题：" name="title"
                       rules={[{ required: true, message: '请输入广告位标题' }]}>
              <Input placeholder="请输入广告位标题" />
            </Form.Item>
            <Form.Item label="选择商品集：" name="collectionId"
                       rules={[{ required: true, message: '请选择商品集' }]}>
              <Select placeholder="请选择商品集">
                {collections.map((item: any) => (
                  <Option key={item.id} value={item.id}>{item.title}</Option>
                ))}
              </Select>
            </Form.Item>
            {formData.advertises.map((item, index) => (
              <Card key={item?.key} title={`广告位 ${index + 1}`} style={{ marginBottom: 16 }}
                    extra={formData.advertises.length > 1 && (
                      <DeleteOutlined onClick={() => removeItem(item?.key)}
                                      style={{ color: 'red', cursor: 'pointer' }} />
                    )}
              >
                <Row gutter={16}>
                  <Col span={12} style={{display: 'none'}}>
                    <Form.Item label="" name={['advertises', index, 'key']}>
                      <Input maxLength={60} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="主标题：" name={['advertises', index, 'title']}
                               rules={[{ required: true, message: '请输入主标题' }]}>
                      <Input maxLength={60} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="副标题：" name={['advertises', index, 'subTitle']}
                               rules={[{ required: true, message: '请输入副标题' }]}>
                      <Input maxLength={60} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="位置：" name={['advertises', index, 'position']}
                               rules={[{ required: true, message: '请输入位置' }]}>
                      <Input maxLength={60} />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="跳转链接：" name={['advertises', index, 'link']}
                               rules={[{ required: true, message: '请输入跳转链接' }]}>
                      <Input maxLength={60} />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={12}>
                    <Form.Item label="背景颜色：" name={['advertises', index, 'bgColor']}>
                      <Input type="color" defaultValue="#ffffff" />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item label="箭头颜色：" name={['advertises', index, 'arrowColor']}>
                      <Input type="color" defaultValue="#000000" />
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  label="图片："
                  name={['advertises', index, 'image']}
                  rules={[{ required: true, message: '请上传图片' }]}
                >
                  <Upload
                    beforeUpload={(file) => {
                      handleImageUpload(file, index);
                      return false; // Prevent default upload behavior
                    }}
                    listType="picture"
                    // @ts-ignore
                    fileList={item.image ? [{ uid: index, url: item.image.path }] : []}  // 确保 fileList 更新
                    showUploadList={false}
                  >
                    {item.image?.path ? (
                      <img src={`${IMAGE_PREFIX}${item.image.path}`} alt="uploaded" style={{ width: '100%' }} />
                    ) : (
                      <Button icon={<UploadOutlined />}>点击上传图片</Button>
                    )}
                  </Upload>
                </Form.Item>
              </Card>
            ))}
            {
              formData.advertises.length < 3 && (
                <Form.Item>
                  <Button type="dashed" onClick={addItem} icon={<PlusOutlined />} style={{ width: '100%' }}>
                    添加广告位
                  </Button>
                </Form.Item>
              )
            }
            <Form.Item>
              <Button type="primary" htmlType="submit">保存</Button>
            </Form.Item>
          </Form>
        </Card>
      </Col>
    </Row>
  );
};

export default AdForm;
