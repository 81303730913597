// 路由文件
import {createBrowserRouter} from 'react-router-dom'

import Home from "./pages/home";
import Detail from "./pages/detail";
import Auth from "./pages/auth";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home/>
  },

  {
    path: '/detail',
    element: <Detail/>
  },
  {
    path: '/detail/:id',
    element: <Detail/>
  },
  {
    path: '/auth',
    element: <Auth/>
  },
])

export default router
